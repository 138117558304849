import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "ma-auto", attrs: { "min-height": "500px" } },
    [
      _c(
        VCol,
        {
          staticClass: "ma-auto grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _vm.usuarioPartner
            ? _c(VImg, {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logoBvs,
                  width: "20vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e(),
          !_vm.usuarioPartner
            ? _c(VImg, {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logo,
                  width: "12vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        VCol,
        { staticClass: "ma-auto", attrs: { sm: "12", md: "8", lg: "8" } },
        [
          _c("p", { staticClass: "text-justify font-weight-black mt-8" }, [
            _vm._v(" Olá " + _vm._s(_vm.nomeDevedor) + ", ")
          ]),
          _c("p", { staticClass: "text-justify mt-8" }, [
            _vm._v(
              " Fomos informados que você foi protestado devido ao não pagamento de títulos para a empresa " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " Como você foi protestado, você poderá estar sujeito a diversas restrições relevantes, como registro nos cadastros negativos, limitação no acesso a crédito, contratação de serviços, entre outros. "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " É muito importante que você procure a empresa " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " para organizar o pagamento de valores em aberto. "
            )
          ])
        ]
      ),
      _c(
        VCol,
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "8", lg: "8" }
        },
        [
          _c(VBtn, { staticClass: "ma-auto", attrs: { color: "primary" } }, [
            _vm._v(" Desejo Negociar minha dívida ")
          ]),
          _c("p", { staticClass: "text-justify caption mt-5" }, [
            _vm._v(
              " Caso você já tenha negociado/quitado sua dívida com o " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " , fique tranquilo! Em breve, você receberá orientações sobre os próximos passos do seu procedimento de protesto por e-mail. "
            )
          ]),
          _c("p", { staticClass: "text-justify caption mt-5" }, [
            _vm._v(
              " " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " incentiva que seus clientes procurem o canal oficial de atendimento para negociar diretamente suas dívidas protestadas. "
            )
          ]),
          _c("p", { staticClass: "text-justify caption mt-5" }, [
            _vm._v(
              " O pagamento da presente dívida não impede o protesto de eventuais outros débitos ou parcelas em aberto. "
            )
          ])
        ],
        1
      ),
      _c(
        VCol,
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [_vm._v(" Obrigado, Equipe Recuperi ")]
      ),
      _c(
        VCol,
        {
          staticClass: "text-center grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _c("p", { staticClass: "font-weight-light subtitle-2" }, [
            _vm._v(
              " Observação: Essa é uma prévia do e-mail enviado, utilizamos esse modelo para envio a todos os devedores "
            )
          ]),
          _c(
            VBtn,
            {
              staticClass: "ma-auto",
              attrs: { small: "", color: "grey lighten-3" },
              on: { click: _vm.fecharModal }
            },
            [_vm._v(" Fechar ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }