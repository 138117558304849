import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { staticClass: "ma-auto", attrs: { "min-height": "500px" } },
    [
      _c(
        VCol,
        {
          staticClass: "ma-auto grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _vm.usuarioPartner
            ? _c(VImg, {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logoBvs,
                  width: "20vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e(),
          !_vm.usuarioPartner
            ? _c(VImg, {
                staticClass: "ma-auto",
                attrs: {
                  "align-center": "",
                  "justify-center": "",
                  src: _vm.logo,
                  width: "12vw",
                  alt: "logo-recuperi"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        VCol,
        { staticClass: "ma-auto", attrs: { sm: "12", md: "8", lg: "8" } },
        [
          _c("p", { staticClass: "text-justify font-weight-black mt-8" }, [
            _vm._v(" Olá " + _vm._s(_vm.nomeDevedor) + ", ")
          ]),
          _c("p", { staticClass: "text-justify mt-8" }, [
            _vm._v(
              " Verificamos que a empresa " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                " enviou o comando de anuência para o cartório responsável por seu protesto, conforme anexo. "
            )
          ]),
          _c("p", { staticClass: "text-justify" }, [
            _vm._v(
              " Isso significa que sua dívida foi regularizada perante o " +
                _vm._s(_vm.nomeExibicaoApresentante) +
                ", porém ainda é necessário pagar as taxas e emolumentos do cartório responsável. "
            )
          ]),
          _c(
            VCard,
            {
              staticClass: "pa-6 font-weight-bold white--text",
              attrs: { color: "primary" }
            },
            [
              _c("p", { staticClass: "text-left" }, [
                _vm._v(
                  " Importante: Para retirar o protesto e limpar seu nome, você deve entrar em contato com o cartório responsável (presencialmente ou por telefone), e informar que empresa " +
                    _vm._s(_vm.nomeExibicaoApresentante) +
                    " enviou a “anuência eletrônica via sistema C.R.A”. "
                )
              ])
            ]
          ),
          _c("p", { staticClass: "text-justify mt-6" }, [
            _vm._v(
              " Para consultar o cartório onde está seu protesto consulte no botão abaixo com seu CPF/CNPJ. "
            )
          ]),
          _c(
            VCol,
            { staticClass: "ma-auto text-center" },
            [
              _c(
                VBtn,
                {
                  staticClass: "ma-auto text-center",
                  attrs: { color: "primary" }
                },
                [_vm._v(" Consultar Protestos e Cartório ")]
              )
            ],
            1
          ),
          _c("p", { staticClass: "text-justify caption mt-6" }, [
            _vm._v(
              " Os títulos de SP e de alguns outros estados já estão com disponibilidade para imprimir o boleto das custas direto no site da Cenprot no botão acima, desta maneira não é necessário entrar em contato com o cartório. "
            )
          ]),
          _c("p", { staticClass: "text-justify caption mt-6" }, [
            _vm._v(
              " O sistema da Recuperi é 100% eletrônico e não é necessário apresentar documentos adicionais, como a carta de anuência. "
            )
          ]),
          _c("p", { staticClass: "text-justify caption" }, [
            _vm._v(
              " É muito importante que você procure o cartório responsável pelo seu protesto dentro de 72 horas dessa comunicação para finalizar o processo de anuência e retirar o protesto de seu nome assim que possível. "
            )
          ])
        ],
        1
      ),
      _c(
        VCol,
        {
          staticClass: "ma-auto text-center",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [_vm._v(" Obrigado, Equipe Recuperi ")]
      ),
      _c(
        VCol,
        {
          staticClass: "text-center grey lighten-5",
          attrs: { sm: "12", md: "12", lg: "12" }
        },
        [
          _c("p", { staticClass: "font-weight-light subtitle-2" }, [
            _vm._v(
              " Observação: Essa é uma prévia do e-mail enviado, utilizamos esse modelo para envio a todos os devedores "
            )
          ]),
          _c(
            VBtn,
            {
              staticClass: "ma-auto",
              attrs: { small: "", color: "grey lighten-3" },
              on: { click: _vm.fecharModal }
            },
            [_vm._v(" Fechar ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }